// this file is for everything not related to Material.

////// angular material adds this, it is supposed to be the first run css

html,
body {
  height: 100%;
}
body {
  margin: 0;
  //font-family: Roboto, "Helvetica Neue", sans-serif; // original added by angular
  font-family: "Poppins", sans-serif; // should match typography in theming file
}
/////////////

:root {
  --border-radius: 7px; // or any global you wish to share with components
  --max-width: 1400px;
}

.wrappable {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
// don't do this because it affects diaglog components
// mat-card {
//   margin: 10px;
// }

.text-with-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

/* Example for mobile */

.mobile-only {
  display: block;
}
.desktop-only {
  width: 0;
  display: none;
  position: absolute; // remove from flex to stop filckering
}
@media (max-width: 800px) {
  .content-area {
    width: auto;
    padding: 16px;
  }
}

// show desktop only when larger screen
@media (min-width: 800px) {
  .mobile-only {
    display: none;
    position: absolute; // remove from flex to stop filckering
  }
  .desktop-only {
    display: block;
  }
}

/* landing page styles need to affect all children, so we put it in global instead under app-landing-page */

// content spacing mateiral
/* Example for desktop */
@media (min-width: 1440px) {
  .content-area {
    max-width: 1200px;
    margin: 0 auto;
  }
}

/* Example for tablet */
@media (min-width: 960px) and (max-width: 1439px) {
  .content-area {
    max-width: 920px;
    margin: 0 auto;
  }
}

body {
  background: var(--mat-sys-surface);
  color: var(--mat-sys-on-surface);
}

a {
  color: var(--mat-sys-on-primary-container);
  text-decoration: underline;
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.error {
  background: var(--mat-sys-error);
  color: var(--mat-sys-on-error);
}

.spacer {
  flex: 1 1 auto;
}

.cssload-container {
  display: none;
}

.is-loading > .cssload-container {
  display: block;
  margin-left: 9px;
}

.is-loading > .cssload-container > .cssload-speeding-wheel {
  width: 21px;
  height: 21px;
  margin: 0 auto;
  border: 1px solid rgb(0, 0, 0);
  border-radius: 50%;
  border-left-color: transparent;
  border-right-color: transparent;
  animation: cssload-spin 425ms infinite linear;
  -o-animation: cssload-spin 425ms infinite linear;
  -ms-animation: cssload-spin 425ms infinite linear;
  -webkit-animation: cssload-spin 425ms infinite linear;
  -moz-animation: cssload-spin 425ms infinite linear;
}

.button-row > button,
.button-row > a {
  margin-left: 8px;
}

@keyframes cssload-spin {
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes cssload-spin {
  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes cssload-spin {
  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes cssload-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes cssload-spin {
  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.thin-toolbar {
  height: 50px !important;
}

.headerbar {
  font-size: xx-large;
  height: 86px;
}

.md-drppicker th {
  color: unset !important;
}
.md-drppicker,
.md-drppicker td.start-date.end-date {
  border-radius: var(--border-radius) !important;
}

.icon-suffix {
  padding-right: 4px;
}

.button-icon {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  background-color: white;
}

.mat-mdc-mini-fab.mat-accent {
  background-color: white;
  margin: 4px 5px 4px 0px;
}

.cal-month-view {
  min-width: 270px;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.lds-hourglass {
  z-index: 3; // not as important as error message
  animation: lds-hourglass 2s infinite;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

.example-button-row .mat-mdc-button-base {
  margin: 8px 8px 8px 0;
}

.center {
  margin: 0 auto;
}

.minutes-input {
  max-width: 150px;
}

.inbutton-icon {
  height: 18px;
  width: 18px;
  margin-right: 18px;
}

// from core.scss-theme
.full-width {
  width: 100%;
}

.link-hover-effect:hover {
  cursor: pointer;
}

.link {
  color: var(--mat-sys-on-primary-container);
  text-decoration: underline;
}
.underline {
  text-decoration: underline;
}

.invisible {
  visibility: hidden;
}

.red-text {
  color: red;
}

.full-height {
  height: 100%;
}

button {
  text-transform: capitalize;
}

.md-drppicker td.active,
.md-drppicker td.active:hover,
.md-drppicker .ranges ul li button.active {
  background-color: var(--mat-sys-on-primary-container) !important;
}

.button-icon {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  background-color: white;
}

.mat-mdc-mini-fab.mat-accent {
  background-color: white;
  margin: 4px 5px 4px 0px;
}

.super-shadow {
  border: var(--mat-sys-on-primary-container) solid 2px;
  box-shadow: 0 0 10px 0px var(--mat-sys-on-primary-container) !important;
}

.active-link-icon {
  border-bottom: var(--mat-sys-on-primary-container) solid 2px;
}

.right-aligned-header > .mat-content {
  justify-content: start;
}

.emphasis-text {
  text-shadow: 0 0 0.5em var(--mat-sys-on-primary-container),
    0 0 0.5em var(--mat-sys-on-primary-container),
    0 0 0.5em var(--mat-sys-on-primary-container);
}

/* Loader */
.loader,
.loader:before,
.loader:after {
  position: relative;
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: var(--mat-sys-primary);
  font-size: 10px;
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.center-vertical {
  display: flex;
  align-items: center;
}
