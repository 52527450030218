@use "@angular/material" as mat;

@use "_theme-colors" as theme-colors; // needed for angular-calendar
// needed for angular-calendar
/* angular-cli file: src/styles.css */

$defaulttheme: (
  color: (
    theme-type: color-scheme,
    primary: theme-colors.$primary-palette,
    //tertiary: theme-colors.$tertiary-palette,
    use-system-variables: true,
  ),
  //color: mat.$violet-palette,
  // https://material.angular.io/guide/theming#typography-map
  typography: "'Poppins', sans-serif",
  density: 0,
  // can be between 0 and -5. -5 reduces spacing
);

// for meetings
$customtheme: (
  color: (
    theme-type: color-scheme,
    //primary: theme-colors.$primary-palette,
    //tertiary: theme-colors.$tertiary-palette,
    use-system-variables: true,
  ),
  //color: mat.$violet-palette,
  // https://material.angular.io/guide/theming#typography-map
  typography: "'Poppins', sans-serif",
  density: 0,
  // can be between 0 and -5. -5 reduces spacing
);
html {
  color-scheme: light; // important to override the generated color-scheme light from the theme setter.

  // set a theme only for parts
  @include mat.theme($defaulttheme);
}

// apply custom calendar theme which is using global styles so we cannot put it in a component
app-calendr,
app-dashboard-large-agenda {
  .cal-day-cell > button {
    min-width: 10px;
    min-height: 46px;
    padding: 0px;
  }

  .cal-month-view .cal-cell-row:hover {
    background-color: var(--mat-sys-surface);
  }
  .cal-events::-webkit-scrollbar-thumb {
    background: var(--mat-sys-on-primary-container);
  }

  .cal-events::-webkit-scrollbar-thumb:hover {
    background: var(--mat-sys-on-primary-container);
  }

  // this mimics mat-flat-button since we cannot do it through the template properly
  .cal-select-day > button {
    background-color: var(--mat-sys-primary);
    color: var(--mat-sys-on-primary) !important;
  }

  .event-main {
    max-width: 1000px;
    margin: 0 auto;
    padding: 4px;
  }

  .cal-month-view .cal-day-cell {
    min-height: 10px;
  }
  .cal-month-view .cal-day-cell.cal-today {
    background-color: var(--mat-sys-container);
    color: var(--mat-sys-on-container);
    border: 1px solid var(--mat-sys-primary) !important;
    border-radius: var(
      --mdc-text-button-container-shape,
      var(--mat-sys-corner-full)
    );
  }
  .cal-month-view {
    background-color: var(--mat-sys-container);
    color: var(--mat-sys-on-container);
  }
  .cal-month-view .cal-day-cell:not(:last-child) {
    border: 0px;
  }
  .cal-month-view .cal-days .cal-cell-row {
    border: 0px;
  }
  .cal-month-view .cal-days {
    border: 0px;
    border-bottom: 0;
  }
  .cal-month-view .cal-cell-row .cal-cell:hover,
  .cal-month-view .cal-cell.cal-has-events.cal-open {
    background-color: var(--mat-sys-surface);
  }
}

app-availability-calendar {
  .cal-month-view {
    background-color: var(--mat-sys-container);
    color: var(--mat-sys-on-container);
  }
  .cal-month-view .cal-day-cell.cal-today {
    background-color: var(--mat-sys-surface-container);
    color: var(--mat-sys-on-container);
  }
}

mwl-calendar-month-cell,
mwl-calendar-day-view-hour-segment,
mwl-calendar-week-view-hour-segment {
  touch-action: auto !important;
}

// finally allow default styles from angular-calendar
@import "../../../../node_modules/angular-calendar/css/angular-calendar.css";

// toolbar overrides
// Customize the entire app. Change :root to your selector if you want to scope the styles.
:root {
  @include mat.toolbar-overrides(
    (
      container-background-color: var(--mat-sys-primary-container),
      container-text-color: var(--mat-sys-on-primary-container),
    )
  );
}

/*#region landingpage*/

app-landing-page {
  @mixin transition {
    @for $i from 1 through 20 {
      &:nth-child(#{$i}) {
        width: #{20 - ($i * 1)}px;
        height: #{20 - ($i * 1)}px;
        animation-delay: #{0+ ($i * 1.5/20)}s;
      }
    }
  }

  @mixin delay {
    @for $i from 0 through 10 {
      &:nth-child(#{$i}) {
        animation-delay: #{0+ ($i * 0.5)}s;
      }
    }
  }
}

/*Navbar on homepage*/
app-navbar {
  @include mat.toolbar-overrides(
    (
      container-background-color: var(--mat-sys-surface),
    )
  );
}

// constrain by the form instead of the field. Let the field take up all space TODO check this with angulars components examples
mat-form-field {
  padding-top: 20px;
}

// items are always too close in cards
mat-card-header {
  margin-bottom: 10px;
}

mat-slide-toggle {
  margin-top: 10px;
  margin-bottom: 10px;
}
